import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { navigate } from "gatsby";
import { Logo } from "../Logo";
import { navItems, docsNav } from "./UiShell.api";
import MenuIcon from "@mui/icons-material/Menu"; // Import Menu icon for the toggle button

const drawerWidth = 240;

interface UiShellDocsProps {
  children: React.ReactNode;
}

export const UiShellDocs: React.FC<UiShellDocsProps> = ({ children }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm")); // Check if the device is small
  const [open, setOpen] = useState(false); // State to control drawer open/close

  const handleDrawerToggle = () => {
    setOpen(!open); // Toggle drawer open/close state
  };

  // Define the links to be conditionally rendered
  const specialLinks = [
    { name: "Home", url: "/" },
    { name: "About", url: "/about" },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        color="transparent"
        elevation={0}
        sx={{ display: { xs: "none", sm: "flex" } }} // Hide AppBar on small devices
      >
        <Toolbar>
          <Box flexGrow={1} />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item, idx) => (
              <Button
                key={idx}
                sx={{ color: theme.palette.primary.main }}
                onClick={() => navigate(item.url)}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isSmall ? "temporary" : "permanent"}
        open={isSmall ? open : undefined} // Only control open/close on small devices
        onClose={handleDrawerToggle} // Handle drawer close on small devices
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Box sx={{ overflow: "auto" }}>
          <Logo />
          <Box my={2}></Box>
          <List>
            {docsNav.map((section, idx) => (
              <ListItem key={idx}>
                <ListItemButton
                  onClick={() => navigate(section.url)}
                  color="primary"
                >
                  {section.name}
                </ListItemButton>
              </ListItem>
            ))}
            {isSmall && (
              <>
                <Box>
                  <Divider />
                </Box>
                {specialLinks.map((item, idx) => (
                  <ListItem key={idx}>
                    <ListItemButton
                      onClick={() => navigate(item.url)}
                      color="primary"
                    >
                      {item.name}
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
      >
        {isSmall && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "block", sm: "none" }, mb: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {children}
      </Box>
    </Box>
  );
};

import { createSlice } from "@reduxjs/toolkit";
// Define the initial state
export interface AppState {
  email: string | null;
  role: string[];
  isAdmin: boolean;
  sub: string | null;
  isLoggedIn: boolean;
  orgName: string | null;
  orgId: string | null;
}

const initialState: AppState = {
  email: null,
  role: [],
  isAdmin: false,
  sub: null,
  isLoggedIn: false,
  orgName: null,
  orgId: null,
};

function getIsAdmin(array: string[]) {
  return array.includes("admin");
}

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setSub: (state, action) => {
      state.sub = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
      state.isAdmin = getIsAdmin(action.payload);
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setOrgName: (state, action) => {
      state.orgName = action.payload;
    },
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
  },
});

export const {
  setEmail,
  setSub,
  setRole,
  setIsLoggedIn,
  setOrgName,
  setOrgId,
} = user.actions;

export default user.reducer;

import React from "react";
import { Typography, useTheme, useMediaQuery } from "@mui/material";

interface SegmentTitleProps {
  children: React.ReactNode;
  color?:
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error"
    | "warning"
    | "info"
    | "white"
    | "success";
}

export const SegmentTitle: React.FC<SegmentTitleProps> = ({
  children,
  color = "primary",
  ...props
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Typography
      variant={isSmall ? "h3" : "h2"}
      color={color}
      fontWeight={800}
      textTransform={`uppercase`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const HOME = "/";
export const DOCS = "/docs";
export const DOCS_GETTING_STARTED = `${DOCS}/getting-started`;
export const DOCS_JAVASCRIPT = `${DOCS}/js`;
export const DOCS_PYTHON = `${DOCS}/python`;
export const ABOUT = "/about";
const AUTH = "/auth";

export const DASHBOARD = `${AUTH}/dashboard`;
const ACCOUNT_MANAGEMENT = `${AUTH}/account-management`;
export const ACCOUNT_MANAGEMENT_TRANSACTIONS = `${ACCOUNT_MANAGEMENT}/transactions`;
export const ACCOUNT_MANAGEMENT_BILLING_DETAILS = `${ACCOUNT_MANAGEMENT}/billing-details`;
export const ACCOUNT_MANAGEMENT_USER_MANAGEMENT = `${ACCOUNT_MANAGEMENT}/user-management`;

const PARTNER_MANAGEMENT = `${AUTH}/partner-management`;
export const PARTNER_MANAGEMENT_PARTNERS = `${PARTNER_MANAGEMENT}/partners`;
export const PARTNER_MANAGEMENT_CREATE_CREDENTIAL = `${PARTNER_MANAGEMENT}/create-credential`;
export const PARTNER_MANAGEMENT_CREATE_PARTNER = `${PARTNER_MANAGEMENT}/create-partner`;

const USE_CASES = `/use-cases`;
export const MORTGAGE_LENDER = `${USE_CASES}/mortgage-lender`;
export const INSURANCE_CARRIER = `${USE_CASES}/insurance-carrier`;
export const LAW_FIRM = `${USE_CASES}/law-firm`;

const RESOURCES = `/resources`;
export const RESOURCES_PRESS_RELEASE = `${RESOURCES}/press-release`;
export const RESOURCES_ARTICLES = `${RESOURCES}/articles`;
export const RESOURCES_PODCAST = `${RESOURCES}/podcast`;

export const PRIVACY_POLICY = `/privacy-policy`;
export const EXPLORE_PARTNERSHIP = `/explore-partnership`;

export const LOGIN = `${AUTH}/dashboard`;

import React from "react";
import { Box, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { content } from "./Social.content";

interface SocialProps {
  smallIcons?: boolean;
}

export const Social: React.FC<SocialProps> = ({ smallIcons }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      justifyContent={isSmall ? `space-between` : `flex-start`}
    >
      {content.social.map((item) => (
        <Box key={item.id} m={2}>
          <a href={item.url} target="_blank" rel="noreferrer">
            <IconButton
              size={smallIcons ? "small" : "medium"}
              sx={{ color: `white` }}
            >
              {React.createElement(item.src, {
                fontSize: smallIcons ? "small" : "large",
              })}
            </IconButton>
          </a>
        </Box>
      ))}
    </Box>
  );
};

function formatString(input: string): string {
  // Remove all whitespace and special characters, then convert to lowercase
  return input.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
}

export const appName: string = "Crittora";
export const appTitle: string = `Crittora: Quantum-Ready Encryption Solutions for the Future`;
export const metaDescription: string = `Prepare your enterprise for the quantum era with Crittora’s cutting-edge, quantum-resistant encryption solutions. Featuring dynamic key rotation, AES 256 encryption, and automatic key management, Crittora ensures data integrity, authenticity, and effortless third-party collaboration. Discover how our solutions can protect your data against future quantum threats.`;
export const metaKeywords: string = `quantum encryption, quantum-resistant encryption, Crittora, AES 256 encryption, dynamic key rotation, data integrity, key management, forward secrecy, third-party collaboration, cybersecurity solutions, Infini-key rotation, data protection, quantum readiness, WUTIF LLC`;

export const siteAuthor = `Wutif`;
export const siteUrl = `https://www.${formatString(appName)}.com`;
export const twitterHandle = `@${formatString(appName)}`;

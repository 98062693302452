import React, { useState } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios"; // Ensure axios is installed
import { useForm, SubmitHandler } from "react-hook-form";
import { POST_ENGAGEMENT_CONTACT } from "../../constants/api";
import { SegmentTitle } from "../../components/SegmentTitle";

interface FormContactFormInputs {
  name: string;
  email: string;
  company: string;
  role: string;
}

type Props = {};

export const FormContact: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormContactFormInputs>();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const onSubmit: SubmitHandler<FormContactFormInputs> = async (data) => {
    try {
      // Replace the URL with your actual API endpoint
      await axios.post(POST_ENGAGEMENT_CONTACT, data);

      // Show success message and clear form
      setSnackbarMessage("Form submitted successfully!");
      setSnackbarSeverity("success");
      reset(); // Clear the form fields
    } catch (error) {
      console.error("Submission error:", error);

      // Show error message
      setSnackbarMessage("Error submitting the form. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Container maxWidth="sm">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box textAlign="left">
                <SegmentTitle color="white" sx={{ fontSize: 34 }}>
                  Contact Us!
                </SegmentTitle>
              </Box>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <TextField
                label="Name"
                {...register("name", { required: "Name is required" })}
                error={!!errors.name}
                helperText={errors.name?.message}
                margin="normal"
                fullWidth
                size="small"
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              />
              <TextField
                label="Email"
                type="email"
                {...register("email", { required: "Email is required" })}
                error={!!errors.email}
                helperText={errors.email?.message}
                margin="normal"
                fullWidth
                size="small"
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              />
              <TextField
                label="Company"
                {...register("company", { required: "Company is required" })}
                error={!!errors.company}
                helperText={errors.company?.message}
                margin="normal"
                fullWidth
                size="small"
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              />
              <TextField
                label="Role"
                {...register("role", { required: "Role is required" })}
                error={!!errors.role}
                helperText={errors.role?.message}
                margin="normal"
                fullWidth
                size="small"
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              />
            </Box>
            <Box display="flex" justifyContent={isSmall ? `center` : `right`}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: "20px" }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Container>
      </Grid>

      {/* Snackbar for alerts */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

const BASE_URL = process.env.GATSBY_BASE_URL;
const BASE_URL_ENGAGEMENT = process.env.GATSBY_BASE_URL_ENGAGEMENT;

export const ENCRYPT = `${BASE_URL}/encrypt`;
export const DECRYPT = `${BASE_URL}/decrypt`;

export const GET_TRANSACTION = `${BASE_URL}/transactions`; // Replace with your actual endpoint
export const GET_BILLING_DETAILS = `${BASE_URL}/billing-details`; // Replace with your actual endpoint
export const GET_PARTNERS = `${BASE_URL}/partners`; // Replace with your aSocialctual endpoint
export const GET_PARTNER_DETAILS = `${GET_PARTNERS}`; // Replace with your actual endpoint
export const POST_PARTNER = `${BASE_URL}/partners`; // Replace with your actual endpoint
export const POST_PARTNER_CREATE_CREDENTIAL = `${POST_PARTNER}/credential`; // Replace with your actual endpoint
export const DELETE_PARTNER = `${BASE_URL}/partners`;
export const PUT_EDIT_PARTNER = `${BASE_URL}/partners`;
export const DELETE_USER_CREDENTIAL = `${POST_PARTNER}/credential`;
export const PATCH_EDIT_USER_CREDENTIAL = `${POST_PARTNER}/credential`;

export const POST_SIGN_AND_ENCRYPT = `${BASE_URL}/sign-and-encrypt`;
export const POST_SIGN = `${BASE_URL}/sign`;
export const POST_ENCRYPT = `${BASE_URL}/encrypt`;

export const POST_VERIFY_AND_DECRYPT = `${BASE_URL}/verfiy-and-decrypt`;
export const POST_VERIFY = `${BASE_URL}/verfiy`;
export const POST_DECRYPT = `${BASE_URL}/decrypt`;

export const GET_ORGANIZATION_NAME = `${BASE_URL}/organization`; // Replace with your actual endpoint

export const POST_RETURN = `${BASE_URL}/return`;

/* Engagement API's */
export const POST_ENGAGEMENT_CONTACT = `${BASE_URL_ENGAGEMENT}/contact`;
export const POST_ENGAGEMENT_CONSULTATION = `${BASE_URL_ENGAGEMENT}/consultation`;

import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import { UiShell, UiShellAuth, UiShellDocs } from "./src/components/UiShell";
import { AuthProvider } from "./src/components/AuthProvider";
import wrapWithProvider from "./wrap-with-provider";
import { ConsentBanner } from "./src/components/ConsentBanner";

export const wrapRootElement = wrapWithProvider;

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  const { location } = props;
  if (location.pathname.startsWith("/auth")) {
    return (
      <AuthProvider>
        <UiShellAuth>{element}</UiShellAuth>
      </AuthProvider>
    );
  }
  if (location.pathname.startsWith("/docs")) {
    return (
      <UiShellDocs>
        {element}
        <ConsentBanner />
      </UiShellDocs>
    );
  }

  return (
    <UiShell>
      {element}
      <ConsentBanner />
    </UiShell>
  );
};

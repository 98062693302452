import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const drawerWidth = 340;

export const StyledUiShell = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down("sm")]: {
    padding: 2,
  },
}));

import { useState, useEffect } from "react";
import { fetchAuthSession, JWT } from "aws-amplify/auth";

interface UseAuthTokenResult {
  token: JWT | null;
  tokenError: Error | null;
  tokenIsLoading: boolean;
}

export const useGetAuthToken = (): UseAuthTokenResult => {
  const [token, setToken] = useState<JWT | null>(null);
  const [tokenError, setTokenError] = useState<Error | null>(null);
  const [tokenIsLoading, setTokenIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function currentSession() {
      try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        setToken(idToken ?? null); // Convert undefined to null
      } catch (err) {
        setTokenError(err as Error);
      } finally {
        setTokenIsLoading(false);
      }
    }

    currentSession();
  }, []);

  return { token, tokenError, tokenIsLoading };
};

import { PRIVACY_POLICY, EXPLORE_PARTNERSHIP } from "../../../constants/routes";

export const content = {
  title: `Crittora, powered by WUTIF LLC | All Rights Reserved`,
  section: {
    privacy: {
      list: [
        {
          title: "Privacy",
          action: "handleClickNavigate",
          url: PRIVACY_POLICY,
        },
        {
          title: `Explore a Partnership`,
          action: `handleClickOpenModal`,
          url: EXPLORE_PARTNERSHIP,
        },
      ],
    },
  },
};

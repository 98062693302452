// src/store/slices/contactSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the shape of the consultation form data
interface ConsultationFormInputs {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  company: string;
  industry: string;
  numberOfEmployees: number;
  state: string;
  country: string;
  upgradeTiming: string;
}

// Define the shape of the contact form data
interface ContactFormInputs {
  name: string;
  email: string;
  company: string;
  role: string;
}

// Define the shape of the slice state
interface ContactState {
  isModalOpen: boolean;
  modalType: "consultation" | "contact" | null;
  formData: ConsultationFormInputs | ContactFormInputs | null;
}

// Define the initial state
const initialState: ContactState = {
  isModalOpen: false,
  modalType: null,
  formData: null,
};

// Create the slice
export const contact = createSlice({
  name: "contact",
  initialState,
  reducers: {
    // Action to open the modal
    openModal(state, action: PayloadAction<"consultation" | "contact">) {
      state.isModalOpen = true;
      state.modalType = action.payload;
    },
    // Action to close the modal
    closeModal(state) {
      state.isModalOpen = false;
      state.modalType = null;
    },
    // Action to set the form data
    setFormData(
      state,
      action: PayloadAction<ConsultationFormInputs | ContactFormInputs>
    ) {
      state.formData = action.payload;
    },
    // Action to clear the form data
    clearFormData(state) {
      state.formData = null;
    },
  },
});

// Export actions
export const { openModal, closeModal, setFormData, clearFormData } =
  contact.actions;

// Export reducer
export default contact.reducer;

import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { POST_ENGAGEMENT_CONSULTATION } from "../../constants/api";
import { LoadingButton } from "@mui/lab";

// Define the form data type
interface ConsultationFormInputs {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  company: string;
  industry: string;
  numberOfEmployees: number;
  state: string;
  country: string;
  upgradeTiming: string;
}

// Props for FormConsultation component
interface FormConsultationProps {
  onSuccess: () => void; // Function to call on successful submission
}

// Modal component
export const FormConsultation: React.FC<FormConsultationProps> = ({
  onSuccess,
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConsultationFormInputs>({
    defaultValues: {
      firstName: "",
      lastName: "",
      role: "",
      email: "",
      company: "",
      industry: "",
      numberOfEmployees: 0,
      state: "",
      country: "",
      upgradeTiming: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onSubmit = async (data: ConsultationFormInputs) => {
    setLoading(true);
    setErrorMessage(""); // Reset error message
    setSuccessMessage(""); // Reset success message
    try {
      // Submit to POST_ENGAGEMENT_CONSULTATION using axios
      const response = await axios.post(POST_ENGAGEMENT_CONSULTATION, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setSuccessMessage(
        response.data.message || "Form submitted successfully!"
      );
      // onSuccess(); // Call the onSuccess function to close the modal
    } catch (error) {
      // Handle submission error
      console.error("Form submission error:", error);
      setErrorMessage("There was an error submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {successMessage ? (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      ) : (
        <>
          <Typography variant="h6" component="h2" id="consultation-modal-title">
            We Look Forward to Meeting You
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={2}
            >
              {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {errorMessage}
                </Alert>
              )}
              <TextField
                label="First Name"
                {...register("firstName", {
                  required: "First name is required",
                })}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                margin="normal"
                fullWidth
                size="small"
              />
              <TextField
                label="Last Name"
                {...register("lastName", { required: "Last name is required" })}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                margin="normal"
                fullWidth
                size="small"
              />
              <TextField
                label="Role/Title"
                {...register("role", { required: "Role/Title is required" })}
                error={!!errors.role}
                helperText={errors.role?.message}
                margin="normal"
                fullWidth
                size="small"
              />
              <TextField
                label="Email"
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email address",
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
                margin="normal"
                fullWidth
                size="small"
              />
              <TextField
                label="Company"
                {...register("company", { required: "Company is required" })}
                error={!!errors.company}
                helperText={errors.company?.message}
                margin="normal"
                fullWidth
                size="small"
              />
              <TextField
                label="Industry"
                {...register("industry", { required: "Industry is required" })}
                error={!!errors.industry}
                helperText={errors.industry?.message}
                margin="normal"
                fullWidth
                size="small"
              />
              <TextField
                label="Number of Employees"
                type="number"
                {...register("numberOfEmployees", {
                  required: "Number of employees is required",
                  min: {
                    value: 1,
                    message: "Number of employees must be at least 1",
                  },
                })}
                error={!!errors.numberOfEmployees}
                helperText={errors.numberOfEmployees?.message}
                margin="normal"
                fullWidth
                size="small"
              />
              <TextField
                label="State"
                {...register("state", { required: "State is required" })}
                error={!!errors.state}
                helperText={errors.state?.message}
                margin="normal"
                fullWidth
                size="small"
              />
              <TextField
                label="Country"
                {...register("country", { required: "Country is required" })}
                error={!!errors.country}
                helperText={errors.country?.message}
                margin="normal"
                fullWidth
                size="small"
              />
              <TextField
                select
                label="When are you looking to upgrade your info security?"
                {...register("upgradeTiming", {
                  required: "Please select a time frame",
                })}
                error={!!errors.upgradeTiming}
                helperText={errors.upgradeTiming?.message}
                margin="normal"
                fullWidth
                size="small"
              >
                <MenuItem value="immediately">Immediately</MenuItem>
                <MenuItem value="3 months">In 3 months</MenuItem>
                <MenuItem value="6 months">In 6 months</MenuItem>
                <MenuItem value="more">6 months or more</MenuItem>
              </TextField>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                loading={loading}
                sx={{ mt: 2 }}
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </>
      )}
    </Box>
  );
};

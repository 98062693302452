exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-auth-account-management-billing-details-tsx": () => import("./../../../src/pages/auth/account-management/billing-details.tsx" /* webpackChunkName: "component---src-pages-auth-account-management-billing-details-tsx" */),
  "component---src-pages-auth-account-management-index-tsx": () => import("./../../../src/pages/auth/account-management/index.tsx" /* webpackChunkName: "component---src-pages-auth-account-management-index-tsx" */),
  "component---src-pages-auth-account-management-transactions-tsx": () => import("./../../../src/pages/auth/account-management/transactions.tsx" /* webpackChunkName: "component---src-pages-auth-account-management-transactions-tsx" */),
  "component---src-pages-auth-account-management-user-management-tsx": () => import("./../../../src/pages/auth/account-management/user-management.tsx" /* webpackChunkName: "component---src-pages-auth-account-management-user-management-tsx" */),
  "component---src-pages-auth-dashboard-tsx": () => import("./../../../src/pages/auth/dashboard.tsx" /* webpackChunkName: "component---src-pages-auth-dashboard-tsx" */),
  "component---src-pages-auth-partner-management-create-credential-[partnerid]-tsx": () => import("./../../../src/pages/auth/partner-management/create-credential/[partnerid].tsx" /* webpackChunkName: "component---src-pages-auth-partner-management-create-credential-[partnerid]-tsx" */),
  "component---src-pages-auth-partner-management-create-partner-index-tsx": () => import("./../../../src/pages/auth/partner-management/create-partner/index.tsx" /* webpackChunkName: "component---src-pages-auth-partner-management-create-partner-index-tsx" */),
  "component---src-pages-auth-partner-management-create-partner-success-index-tsx": () => import("./../../../src/pages/auth/partner-management/create-partner-success/index.tsx" /* webpackChunkName: "component---src-pages-auth-partner-management-create-partner-success-index-tsx" */),
  "component---src-pages-auth-partner-management-partners-[partnerid]-tsx": () => import("./../../../src/pages/auth/partner-management/partners/[partnerid].tsx" /* webpackChunkName: "component---src-pages-auth-partner-management-partners-[partnerid]-tsx" */),
  "component---src-pages-auth-partner-management-partners-index-tsx": () => import("./../../../src/pages/auth/partner-management/partners/index.tsx" /* webpackChunkName: "component---src-pages-auth-partner-management-partners-index-tsx" */),
  "component---src-pages-docs-getting-started-tsx": () => import("./../../../src/pages/docs/getting-started.tsx" /* webpackChunkName: "component---src-pages-docs-getting-started-tsx" */),
  "component---src-pages-docs-js-tsx": () => import("./../../../src/pages/docs/js.tsx" /* webpackChunkName: "component---src-pages-docs-js-tsx" */),
  "component---src-pages-docs-python-tsx": () => import("./../../../src/pages/docs/python.tsx" /* webpackChunkName: "component---src-pages-docs-python-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-resources-articles-tsx": () => import("./../../../src/pages/resources/articles.tsx" /* webpackChunkName: "component---src-pages-resources-articles-tsx" */),
  "component---src-pages-resources-podcast-tsx": () => import("./../../../src/pages/resources/podcast.tsx" /* webpackChunkName: "component---src-pages-resources-podcast-tsx" */),
  "component---src-pages-resources-press-release-tsx": () => import("./../../../src/pages/resources/press-release.tsx" /* webpackChunkName: "component---src-pages-resources-press-release-tsx" */),
  "component---src-pages-use-cases-insurance-carrier-tsx": () => import("./../../../src/pages/use-cases/insurance-carrier.tsx" /* webpackChunkName: "component---src-pages-use-cases-insurance-carrier-tsx" */),
  "component---src-pages-use-cases-law-firm-tsx": () => import("./../../../src/pages/use-cases/law-firm.tsx" /* webpackChunkName: "component---src-pages-use-cases-law-firm-tsx" */),
  "component---src-pages-use-cases-mortgage-lender-tsx": () => import("./../../../src/pages/use-cases/mortgage-lender.tsx" /* webpackChunkName: "component---src-pages-use-cases-mortgage-lender-tsx" */)
}


// src/store/slices/uishell.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UiShellState {
  scrollY: number;
  prevScrollY: number;
  scrollStarted: boolean;
  activeSegment: number | null;
}

const initialState: UiShellState = {
  scrollY: 0,
  prevScrollY: 0,
  scrollStarted: false,
  activeSegment: 0,
};

export const uishell = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    setScrollY: (state, action: PayloadAction<number>) => {
      state.prevScrollY = state.scrollY;
      state.scrollY = action.payload;
    },
    setScrollStarted: (state, action: PayloadAction<boolean>) => {
      state.scrollStarted = action.payload;
    },
    setActiveSegment: (state, action: PayloadAction<number | null>) => {
      state.activeSegment = action.payload;
    },
  },
});

export const { setScrollY, setScrollStarted, setActiveSegment } =
  uishell.actions;

export default uishell.reducer;

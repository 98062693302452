import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserAttributes } from "aws-amplify/auth";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Stack,
  Skeleton,
} from "@mui/material";
import { UiShellDrawer } from "./UiShellDrawer";
import { GET_ORGANIZATION_NAME } from "../../constants/api";
import { drawerWidth } from "./UiShell.styles";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setOrgName, setOrgId } from "../../store/reducers/user.reducer";
import { useGetAuthToken } from "../../hooks/useGetAuthToken";

interface UiShellAuthProps {
  children: React.ReactNode;
}

export const UiShellAuth: React.FC<UiShellAuthProps> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const [localOrgName, setLocalOrgName] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { token, tokenError, tokenIsLoading } = useGetAuthToken();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    async function handleFetchUserAttributes() {
      try {
        const userAttributes = await fetchUserAttributes();
        const orgIdAttribute = userAttributes
          ? userAttributes["custom:orgId"]
          : null;
        dispatch(setOrgId(orgIdAttribute || null));
      } catch (error) {
        console.log(error);
      }
    }

    handleFetchUserAttributes();
  }, []);

  useEffect(() => {
    async function fetchOrgName() {
      if (token) {
        try {
          const url = `${GET_ORGANIZATION_NAME}`;
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await axios.get(url, config);
          setLocalOrgName(response.data.name);
          dispatch(setOrgName(response.data.name));
        } catch (error) {
          if (error instanceof Error) {
            console.log(error.message);
          } else {
            console.log(String(error));
          }
        }
      }
    }

    fetchOrgName();
  }, [token]);

  if (tokenIsLoading) return <p>Loading...</p>;
  if (tokenError) return <p>{`Error: ${tokenError}`}</p>;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        elevation={0}
        color="transparent"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: `#fff`,
          borderBottom: `1px solid #e0e0e0`,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" justifyContent={`space-between`} width={`100%`}>
            <Typography variant="subtitle2">{`${localOrgName}`}</Typography>
            <Stack direction={`row`} spacing={2}>
              {!user.signInDetails?.loginId ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              ) : (
                <Typography variant="subtitle2">{`${user.signInDetails?.loginId}`}</Typography>
              )}
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <UiShellDrawer
          mobileOpen={mobileOpen}
          handleDrawerClose={handleDrawerClose}
          handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        />
      </Box>
      <Box component="main" pt={`128px`} px={5} pb={5} width="100%">
        {children}
      </Box>
    </Box>
  );
};

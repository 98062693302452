import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import KeyIcon from "@mui/icons-material/Key";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DashboardIcon from "@mui/icons-material/Dashboard";

import {
  DASHBOARD,
  ACCOUNT_MANAGEMENT_BILLING_DETAILS,
  ACCOUNT_MANAGEMENT_TRANSACTIONS,
  ACCOUNT_MANAGEMENT_USER_MANAGEMENT,
  PARTNER_MANAGEMENT_PARTNERS,
  PARTNER_MANAGEMENT_CREATE_CREDENTIAL,
  PARTNER_MANAGEMENT_CREATE_PARTNER,
} from "../../constants/routes";
import { title } from "process";
import { sign } from "crypto";

export const content = {
  title: "ContentUiShell",
  navigation: [
    {
      title: `Dashboard`,
      route: DASHBOARD,
      icon: DashboardIcon,
    },
    {
      title: `Account Management`,
      route: null,
      icon: ManageAccountsIcon,
      navigation: [
        {
          title: `Transactions`,
          route: ACCOUNT_MANAGEMENT_TRANSACTIONS,
          icon: null,
        },
        // {
        //   title: `Billing Details`,
        //   route: ACCOUNT_MANAGEMENT_BILLING_DETAILS,
        //   icon: null,
        // },
        // {
        //   title: `User Management`,
        //   route: ACCOUNT_MANAGEMENT_USER_MANAGEMENT,
        //   icon: null,
        // },
      ],
    },
    {
      title: `Partner Management`,
      route: null,
      icon: GroupWorkIcon,
      navigation: [
        {
          title: `Partners`,
          route: PARTNER_MANAGEMENT_PARTNERS,
          icon: null,
        },
        {
          title: `Create Partner`,
          route: PARTNER_MANAGEMENT_CREATE_PARTNER,
          icon: null,
        },
      ],
    },
    // {
    //   title: `Sign / Encrypt`,
    //   route: null,
    //   icon: KeyIcon,
    //   navigation: [
    //     {
    //       title: `Sign & Encrypt`,
    //       route: SIGN_ENCRYPT_SIGN_AND_ENCRYPT,
    //       icon: null,
    //     },
    //     {
    //       title: `Sign`,
    //       route: SIGN_ENCRYPT_SIGN,
    //       icon: null,
    //     },
    //     {
    //       title: `Encrypt`,
    //       route: SIGN_ENCRYPT_ENCRYPT,
    //       icon: null,
    //     },
    //   ],
    // },
    // {
    //   title: `Verify / Decrypt`,
    //   route: null,
    //   icon: LockOpenIcon,
    //   navigation: [
    //     {
    //       title: `Verify & Decrypt`,
    //       route: VERIFY_DECRYPT_VERIFY_AND_DECRYPT,
    //       icon: null,
    //     },
    //     {
    //       title: `Verify`,
    //       route: VERIFY_DECRYPT_VERIFY,
    //       icon: null,
    //     },
    //     {
    //       title: `Decrypt`,
    //       route: VERIFY_DECRYPT_DECRYPT,
    //       icon: null,
    //     },
    //   ],
    // },
  ],
};

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user.reducer";
import uishellReducer from "./reducers/uishell.reducer";
import contactReducer from "./reducers/contact.reducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    uishell: uishellReducer,
    contact: contactReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:385ba20f-37e7-4ebc-bc50-eaa54a4a6aa0",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_2QhtF8qgE",
  aws_user_pools_web_client_id: "6lnff6ip0ekoos4feqed9nab0r",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "ON",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 12,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;

import { uid } from "uid";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";

export const content = {
  title: "Social",
  social: [
    {
      id: uid(),
      name: "Telegram",
      src: TelegramIcon,
      url: "https://t.me/+eNycu4f399A1OWUx",
    },
    {
      id: uid(),
      name: "X",
      src: XIcon,
      url: "https://x.com/Crittora",
    },
    {
      id: uid(),
      name: "LinkedIn",
      src: LinkedInIcon,
      url: "https://www.linkedin.com/company/crittora/",
    },
  ],
};

import React, { useState } from "react";
import { navigate } from "gatsby";
import {
  Container,
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { FormContact } from "../../FormContact";
import { Social } from "../../Social";
import { FormConsultation } from "../../../components/FormConsultation";

import { content } from "./Footer.content";
import BgFooter from "../../../images/footer/bg-footer.svg";
import LogoTextWhite from "../../../images/logo-text-white.svg";

type Props = {};

export const Footer: React.FC = (props: Props) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleClickOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleClickNavigate = (url: string) => {
    navigate(url);
  };

  const handleAction = (action: string, url: string) => {
    if (action === "handleClickNavigate") {
      handleClickNavigate(url);
    } else if (action === "handleClickOpenModal") {
      handleClickOpenModal();
    }
  };

  return (
    <Box
      sx={{
        background: `url(${BgFooter})`,
        backgroundSize: "cover",
      }}
      p={4}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {content.section.privacy.list.map((item, index) => (
                <Grid item key={index}>
                  <Button
                    onClick={() => handleAction(item.action, item.url)}
                    variant="text"
                    sx={{ color: `white`, fontSize: 18 }}
                  >
                    {item.title}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  my={6}
                  component="img"
                  src={LogoTextWhite}
                  alt="Crittora"
                  width={isSmall ? 250 : 350}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Social />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography color="white">{content.title}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormContact />
          </Grid>
        </Grid>
      </Container>
      <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Consultation Form</DialogTitle>
        <DialogContent>
          <FormConsultation onSuccess={handleCloseModal} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { navigate } from "gatsby";
import {
  ButtonBase,
  AppBar,
  Drawer,
  Toolbar,
  IconButton,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import {
  motion,
  useScroll,
  useMotionValueEvent,
  useAnimation,
} from "framer-motion";
import { navItems } from "./UiShell.api";
import { LOGIN, HOME } from "../../constants/routes";
import { Footer } from "./Footer";
import ImgLogo from "../../images/logo.svg";
import ImgLogoWhite from "../../images/logo-white.svg";
import { UiShellProps, NavItem } from "./UiShell.types";

const drawerWidth = 240;

export const UiShell: React.FC<UiShellProps> = ({ children, navItemColor }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [isVisible, setIsVisible] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { scrollY } = useScroll();
  const scrollTimer = useRef<NodeJS.Timeout | null>(null);
  const controls = useAnimation();

  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrollPosition(latest);
    if (!isSmall) {
      setIsVisible(false);

      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }

      scrollTimer.current = setTimeout(() => {
        setIsVisible(true);
      }, 500);
    }
  });

  useEffect(() => {
    if (!isSmall) {
      controls.start({
        y: isVisible ? 0 : -100,
        transition: { duration: 0.3, ease: "easeInOut" },
      });
    }
  }, [isVisible, controls, isSmall]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLogin = () => {
    navigate(LOGIN);
  };
  const handleClickHome = () => {
    navigate(HOME);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <ButtonBase onClick={handleClickHome}>
        <Box component="img" src={ImgLogo} width={`150px`} />
      </ButtonBase>
      <Divider />
      <List>
        {navItems.map((item: NavItem, idx) => (
          <ListItem key={idx} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => navigate(item.url)}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={handleLogin}>
            <ListItemText primary="Login" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const yThreshold = 200;
  const appBarColor =
    scrollPosition > yThreshold ? `rgba(255,255,255,0.8)` : "transparent";
  const textColor =
    scrollPosition > yThreshold
      ? theme.palette.primary.main
      : navItemColor ?? "#fff";
  const elevationDef =
    scrollPosition > yThreshold
      ? `0px 0px 9px -1px #817f7f5e`
      : `0px 0px 0 0 transparent`;
  const logoDef = scrollPosition > yThreshold ? ImgLogo : ImgLogoWhite;

  const AppBarContent = (
    <AppBar
      component="nav"
      position={isSmall ? "fixed" : "fixed"}
      elevation={0}
      sx={{
        background: isSmall ? "white" : appBarColor,
        boxShadow: elevationDef,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon color="primary" />
        </IconButton>
        <Box
          display="flex"
          justifyContent={`space-between`}
          width="100%"
          alignItems="center"
        >
          <ButtonBase onClick={handleClickHome}>
            <Box
              component="img"
              src={isSmall ? ImgLogo : logoDef}
              width={`150px`}
            />
          </ButtonBase>
          <Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item, idx) => (
                <Button
                  key={idx}
                  sx={{
                    color: isSmall ? theme.palette.primary.main : textColor,
                  }}
                  onClick={() => navigate(item.url)}
                >
                  {item.name}
                </Button>
              ))}
              <IconButton
                color="inherit"
                onClick={handleLogin}
                sx={{
                  color: isSmall ? theme.palette.primary.main : textColor,
                }}
              >
                <LoginIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );

  return (
    <>
      {isSmall ? (
        AppBarContent
      ) : (
        <motion.div
          initial={{ y: 0 }}
          animate={controls}
          style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 1100 }}
        >
          {AppBarContent}
        </motion.div>
      )}
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 0 }}>
        {children}
      </Box>
      <Footer />
    </>
  );
};
